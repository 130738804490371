

/*

Noise shader used to modify the plane of the water,
any noise used should provide the height deformation
and the normals for the fragment modified.

 */


const noiseShader = `
    precision highp float;

    uniform float currentFrame;
    uniform float rippleHeight;
    uniform float rippleFrequency;
    uniform float rippleSpeed;

    varying vec2 uv;

    const float PI	 	= 3.14159265358;
    const float EPSILON	= 1e-6;
    const int NUM_STEPS = 6;
    const int ITER_GEOMETRY = 2;
    const int ITER_FRAGMENT = 10;
    const float SEA_CHOPPY = 10.0;
    #define SEA_TIME (currentFrame * rippleSpeed)

    float scaler = 1.;

    //3D transformation matrix.
    mat3 m = mat3( 0.00,  0.80,  0.60,
        -0.80,  0.36, -0.48,
        -0.60, -0.48,  0.64 );


    //hash function used to calculate the mist
    float hash( float n ) {
        return fract(sin(n)*43758.5453);
    }

    float noise( in vec3 x ) {
        vec3 p = floor(x);
        vec3 f = fract(x);

        f = f*f*(3.0-2.0*f);

        float n = p.x + p.y*57.0 + 113.0*p.z;

        float res = mix(mix(mix( hash(n+  0.0), hash(n+  1.0),f.x),
            mix( hash(n+ 57.0), hash(n+ 58.0),f.x),f.y),
        mix(mix( hash(n+113.0), hash(n+114.0),f.x),
            mix( hash(n+170.0), hash(n+171.0),f.x),f.y),f.z);
        return res;
    }


    float fbm( vec3 p ) {
        float f;
        f += 1.0000 *noise( p ); p = m*p*2.04;
        f += 0.5000 *noise( p ); p = m*p*2.02;
        f += 0.2500 *noise( p ); p = m*p*2.03;
        f += 0.1250 *noise( p ); p = m*p*2.01;
        f += 0.0625 *noise( p );
        return f;
    }

    mat2 octave_m = mat2(1.7,1.2,-1.2,1.4);

    mat3 fromEuler(vec3 ang) {
        vec2 a1 = vec2(sin(ang.x),cos(ang.x));
        vec2 a2 = vec2(sin(ang.y),cos(ang.y));
        vec2 a3 = vec2(sin(ang.z),cos(ang.z));
        mat3 m;
        m[0] = vec3(a1.y*a3.y+a1.x*a2.x*a3.x,a1.y*a2.x*a3.x+a3.y*a1.x,-a2.y*a3.x);
        m[1] = vec3(-a2.y*a1.x,a1.y*a2.y,a2.x);
        m[2] = vec3(a3.y*a1.x*a2.x+a1.y*a3.x,a1.x*a3.x-a1.y*a3.y*a2.x,a2.y*a3.y);
        return m;
    }


    float sea_octave(vec2 uv, float choppy) {
        uv += noise(vec3(uv, 0.));
        vec2 wv = 1.0-abs(sin(uv));
        vec2 swv = abs(cos(uv));
        wv = mix(wv,swv,wv);
        return pow(1.0-pow(wv.x * wv.y,0.65),choppy);
    }


    float map(vec3 p) {
        float freq = rippleFrequency;
        float amp = rippleHeight;
        float choppy = SEA_CHOPPY;
        vec2 uv = p.xz; uv.x *= 0.75;
        float d, h = 0.0;
        for(int i = 0; i < ITER_GEOMETRY; i++) {
            d = sea_octave((uv + SEA_TIME)*freq,choppy);
            h += d * amp;
            uv *=  octave_m;
            freq *= 1.9;
            amp *= 0.22;
            choppy = mix(choppy,1.0,0.2);
        }

        return p.y + h;
    }


    float map_detailed(vec3 p) {
        float freq = rippleFrequency;
        float amp = rippleHeight;
        float choppy = SEA_CHOPPY;
        vec2 uv = p.xz; uv.x *= 0.75;
        float d, h = 0.0;
        for(int i = 0; i < ITER_FRAGMENT; i++) {
            d = sea_octave((uv+SEA_TIME)*freq,choppy);
            d += sea_octave((uv-SEA_TIME)*freq,choppy);
            h += d * amp;
            uv *= octave_m/1.2;
            freq *= 1.9;
            amp *= 0.22;
            choppy = mix(choppy,1.0,0.2);
        }
        return p.y + h;
    }


    vec3 getNormal(vec3 p) {
        vec3 n;
        vec3 eps = vec3(.00001, 0., 0.);
        n.x = map_detailed(p + eps) - map_detailed(p - eps);
        n.y = map_detailed(p + eps.yxz) - map_detailed(p - eps.yxz);
        n.z = map_detailed(p + eps.yzx) - map_detailed(p - eps.yzx);
        return normalize(n);
    }


    void main() {

        vec3 pos = vec3(10.* vec2(10., 10.) * uv, 0.);
        float height = map_detailed(pos.xzy);
        vec3 normal = getNormal(pos.xzy);

        gl_FragColor = vec4(height, normal);

    }
`;

export {noiseShader}