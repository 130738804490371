
/*
This shader is used to render the particle cloud on the scene
the data is saved in 6 different attributes, but it would be recommended
to use 2 attributes, one for the XYZ position and another for the RGB color
 */


const vertexShader = `

    attribute vec3 position;
    attribute vec3 color;

    uniform mat4 uCameraMatrix;
    uniform mat4 uPMatrix;
    uniform float currentFrame;
    uniform float offset;
    uniform bool levelHeight;
    uniform float sign;

    varying vec4 colorData;

    void main() {

        //The x value is negated to invert the image, this is arbitrary
        //the offset is used to render the particles inverted for the reflection effect

        vec4 pp = vec4(-position.x, sign * position.z + offset, position.y , 1.);


        colorData = vec4(color / 255., 1.);


        //This is done to make the particles have the same height (change the height by steps) for the zLevel
        //it used to smooth the height differences to make the water more smooth.
        //The 20 value is used to define the resolution of the water height (step level)

        if(levelHeight) pp.y = floor(pp.y * 20.) / 20.;


        //This is done to remove the upper part of the negative particles for reflections (The street)
        //Should define a way to make it for all the images, this is image relevant. Should be studied
        //for each image.

        if(pp.y > -0.9 && sign < 0.) pp = vec4(200000.);

        gl_Position = uPMatrix * uCameraMatrix * pp;


        //Size for the regular rendering of the image
        gl_PointSize = 1.7;


        //Size for the reflection particles view, the size is bigger to cover the errors from the offset.
        if(sign < 0.) gl_PointSize = 5.;


        //This makes the particles even bigger when the zLevel smoothing is active
        if(levelHeight) gl_PointSize = 9.;

    }
`;

export {vertexShader}