import React from 'react';
import './App.css';

import {gl}                     from './components/webGL/webGL.js';
import * as webGL              from './components/webGL/webGL.js';
import {Camera}                 from './components/webGL/camera.js';
import * as dat                 from 'dat.gui';

//Shaders
import {blurShader}             from './components/webGL/shaders/blurShader.js';
import {colorShader}            from './components/webGL/shaders/colorShader.js';
import {compoShader}            from './components/webGL/shaders/compoShader.js';
import {heightShader}           from './components/webGL/shaders/heightShader.js';
import {noiseShader}            from './components/webGL/shaders/noiseShader.js';
import {planeShader}            from './components/webGL/shaders/planeShader.js';
import {quadShader}             from './components/webGL/shaders/quadShader.js';
import {subtractShader}         from './components/webGL/shaders/subtractShader.js';
import {vertexShader}           from './components/webGL/shaders/vertexShader.js';
import {waterShader}            from './components/webGL/shaders/waterShader.js';


export default class App extends React.Component {

    componentDidMount() {


        //Generate the 3d context
        this.canvas = document.querySelector(".canvas3D");
        this.size = 1800;
        this.blurDivider = 4;
        this.noiseTextureSize = 100;
        this.planeSegments = 300;
        this.canvas.height = this.canvas.width = this.size;
        this.canvas.style.width = String(this.canvas.width) + "px";
        this.canvas.style.height = String(this.canvas.height) + "px";
        webGL.setContext(this.canvas);
        this.noiseReady = false;


        //Set the camera to render the points
        this.camera = new Camera(this.canvas);
        this.cameraDistance = 10;
        this.FOV = 70;
        this.invertCamera = true;

        this.currentFrame = 0;


        
        //Generate the particles program
        this.renderParticlesProgram = webGL.generateProgram(vertexShader, colorShader);
        this.renderParticlesProgram.position = gl.getAttribLocation(this.renderParticlesProgram, "position");
        this.renderParticlesProgram.color = gl.getAttribLocation(this.renderParticlesProgram, "color");
        this.renderParticlesProgram.cameraMatrix = gl.getUniformLocation(this.renderParticlesProgram, "uCameraMatrix");
        this.renderParticlesProgram.perspectiveMatrix = gl.getUniformLocation(this.renderParticlesProgram, "uPMatrix");
        this.renderParticlesProgram.currentFrame = gl.getUniformLocation(this.renderParticlesProgram, "currentFrame");
        this.renderParticlesProgram.sign = gl.getUniformLocation(this.renderParticlesProgram, "sign");
        this.renderParticlesProgram.offset = gl.getUniformLocation(this.renderParticlesProgram, "offset");
        this.renderParticlesProgram.levelHeight = gl.getUniformLocation(this.renderParticlesProgram, "levelHeight");



        //Generate the water plane program
        this.waterProgram =  webGL.generateProgram(planeShader, waterShader);
        this.waterProgram.vertexPosition = gl.getAttribLocation(this.waterProgram, "vertexPosition");
        this.waterProgram.cameraMatrix = gl.getUniformLocation(this.waterProgram, "uCameraMatrix");
        this.waterProgram.perspectiveMatrix = gl.getUniformLocation(this.waterProgram, "uPMatrix");
        this.waterProgram.waterLevel = gl.getUniformLocation(this.waterProgram, "waterLevel");
        this.waterProgram.cameraPosition = gl.getUniformLocation(this.waterProgram, "cameraPosition");
        this.waterProgram.choppy = gl.getUniformLocation(this.waterProgram, "choppy");
        this.waterProgram.planeSize = gl.getUniformLocation(this.waterProgram, "planeSize");




        //Generate the composition program
        this.compoProgram = webGL.generateProgram(quadShader, compoShader);
        this.compoProgram.indexes = gl.getAttribLocation(this.compoProgram, "aIndexes");
        this.compoProgram.albedo = gl.getUniformLocation(this.compoProgram, "albedo");
        this.compoProgram.fresnel = gl.getUniformLocation(this.compoProgram, "fresnel");
        this.compoProgram.reflection = gl.getUniformLocation(this.compoProgram, "reflection");
        this.compoProgram.height = gl.getUniformLocation(this.compoProgram, "height");
        this.compoProgram.waterLevel = gl.getUniformLocation(this.compoProgram, "waterLevel");
        this.compoProgram.waterColor = gl.getUniformLocation(this.compoProgram, "waterColor");
        this.compoProgram.dirtyLevel = gl.getUniformLocation(this.compoProgram, "dirtyLevel");
        this.compoProgram.currentFrame = gl.getUniformLocation(this.compoProgram, "currentFrame");
        this.compoProgram.normalDeformation = gl.getUniformLocation(this.compoProgram, "normalDeformation");
        this.compoProgram.specularPower = gl.getUniformLocation(this.compoProgram, "specularPower");
        this.compoProgram.specularIntensity = gl.getUniformLocation(this.compoProgram, "specularIntensity");
        this.compoProgram.thresholdTexture = gl.getUniformLocation(this.compoProgram, "thresholdTexture");
        this.compoProgram.threshold = gl.getUniformLocation(this.compoProgram, "threshold");



        //Generate the height program
        this.heightProgram = webGL.generateProgram(heightShader, colorShader);
        this.heightProgram.position = gl.getAttribLocation(this.heightProgram, "position");
        this.heightProgram.color = gl.getAttribLocation(this.heightProgram, "color");
        this.heightProgram.cameraMatrix = gl.getUniformLocation(this.heightProgram, "uCameraMatrix");
        this.heightProgram.perspectiveMatrix = gl.getUniformLocation(this.heightProgram, "uPMatrix");



        //Generate the noise program
        this.noiseProgram = webGL.generateProgram(quadShader, noiseShader);
        this.noiseProgram.indexes = gl.getAttribLocation(this.noiseProgram, "aIndexes");
        this.noiseProgram.currentFrame = gl.getUniformLocation(this.noiseProgram, "currentFrame");
        this.noiseProgram.rippleHeight = gl.getUniformLocation(this.noiseProgram, "rippleHeight");
        this.noiseProgram.rippleSpeed = gl.getUniformLocation(this.noiseProgram, "rippleSpeed");
        this.noiseProgram.rippleFrequency = gl.getUniformLocation(this.noiseProgram, "rippleFrequency");



        //Generate the subtract program
        this.subtractProgram = webGL.generateProgram(quadShader, subtractShader);
        this.subtractProgram.indexes = gl.getAttribLocation(this.subtractProgram, "aIndexes");
        this.subtractProgram.texture1 = gl.getUniformLocation(this.subtractProgram, "texture1");
        this.subtractProgram.texture2 = gl.getUniformLocation(this.subtractProgram, "texture2");



        //Generate the blur program
        this.blurProgram = webGL.generateProgram(quadShader, blurShader);
        this.blurProgram.indexes = gl.getAttribLocation(this.blurProgram, "aIndexes");
        this.blurProgram.texture = gl.getUniformLocation(this.blurProgram, "uDT");
        this.blurProgram.axis = gl.getUniformLocation(this.blurProgram, "uAxis");
        this.blurProgram.steps = gl.getUniformLocation(this.blurProgram, "uSteps");

        const type = /(iPad|iPhone|iPod)/g.test(navigator.userAgent) ? webGL.hfExtension.HALF_FLOAT_OES : gl.FLOAT;

        //Generate textures and framebuffers for the compositions
        this.albedoTexture = webGL.createTexture2D(this.size, this.size, gl.RGBA, gl.RGBA, gl.LINEAR, gl.LINEAR, gl.UNSIGNED_BYTE);
        this.reflectionTexture = webGL.createTexture2D(this.size, this.size, gl.RGBA, gl.RGBA, gl.LINEAR, gl.LINEAR, gl.UNSIGNED_BYTE);
        this.fresnelTexture = webGL.createTexture2D(this.size, this.size, gl.RGBA, gl.RGBA, gl.LINEAR, gl.LINEAR, gl.UNSIGNED_BYTE);
        this.waterPositionTexture = webGL.createTexture2D(this.size, this.size, gl.RGBA, gl.RGBA, gl.NEAREST, gl.NEAREST, type);
        this.heightTexture = webGL.createTexture2D(this.size, this.size, gl.RGBA, gl.RGBA, gl.NEAREST, gl.NEAREST, type);
        this.noiseTexture = webGL.createTexture2D(this.noiseTextureSize, this.noiseTextureSize, gl.RGBA, gl.RGBA, gl.LINEAR, gl.LINEAR, gl.UNSIGNED_BYTE);
        this.blur1Texture = webGL.createTexture2D(this.size / this.blurDivider, this.size / this.blurDivider, gl.RGBA, gl.RGBA, gl.LINEAR, gl.LINEAR, gl.UNSIGNED_BYTE);
        this.blur2Texture = webGL.createTexture2D(this.size / this.blurDivider, this.size / this.blurDivider, gl.RGBA, gl.RGBA, gl.LINEAR, gl.LINEAR, gl.UNSIGNED_BYTE);



        this.albedoFB = webGL.createDrawFramebuffer([this.albedoTexture], true);
        this.reflectionFB = webGL.createDrawFramebuffer([this.reflectionTexture], true);
        this.fresnelFB = webGL.createDrawFramebuffer([this.fresnelTexture], true);
        this.waterPositionFB = webGL.createDrawFramebuffer([this.waterPositionTexture], true);
        this.heightFB = webGL.createDrawFramebuffer([this.heightTexture], true);
        this.noiseFB = webGL.createDrawFramebuffer([this.noiseTexture]);
        this.blur1FB = webGL.createDrawFramebuffer([this.blur1Texture]);
        this.blur2FB = webGL.createDrawFramebuffer([this.blur2Texture]);


        //Generate the information for the plane
        this.planeWidth = 50;
        this.planeHeight = 100;
        let widthSegments = this.planeSegments;
        let heightSegments = this.planeSegments;
        let width_half = this.planeWidth / 2;
        let height_half = this.planeHeight / 2;
        let gridX = Math.floor( widthSegments );
        let gridY = Math.floor( heightSegments );
        let gridX1 = gridX + 1;
        let gridY1 = gridY + 1;
        let segment_width = this.planeWidth / gridX;
        let segment_height = this.planeHeight / gridY;
        let ix, iy;
        let verticesArranged = [];
        let vertices = [];

        // generate vertices, normals and uvs
        for ( iy = 0; iy < gridY1; iy ++ ) {
            var y = iy * segment_height - height_half;
            for ( ix = 0; ix < gridX1; ix ++ ) {
                var x = ix * segment_width - width_half;
                vertices.push( [x, y]);
            }
        }

        // vertices arranged
        for ( iy = 0; iy < gridY; iy ++ ) {
            for ( ix = 0; ix < gridX; ix ++ ) {
                var a = ix + gridX1 * iy;
                var b = ix + gridX1 * ( iy + 1 );
                var c = ( ix + 1 ) + gridX1 * ( iy + 1 );
                var d = ( ix + 1 ) + gridX1 * iy;
                // faces

                verticesArranged.push(vertices[a][0], vertices[a][1]);
                verticesArranged.push(vertices[b][0], vertices[b][1]);
                verticesArranged.push(vertices[d][0], vertices[d][1]);

                verticesArranged.push(vertices[b][0], vertices[b][1]);
                verticesArranged.push(vertices[c][0], vertices[c][1]);
                verticesArranged.push(vertices[d][0], vertices[d][1]);
            }
        }

        this.vertices = webGL.createBuffer(verticesArranged);
        this.amountOfTriangles = verticesArranged.length / 3;


        //Load the corresponding data
        let dataToLoad = ["0_x.csv", "0_y.csv", "0_z.csv", "0_r.csv", "0_g.csv", "0_b.csv"];
        this.data = [];
        let filesLoaded = 0;
        const _this = this;


        dataToLoad.map( (file, index) => {

            let txtFile = new XMLHttpRequest();

            txtFile.onreadystatechange = function() {
                if (txtFile.readyState === 4 && txtFile.status === 200) {

                    let allText = txtFile.responseText;
                    allText = allText.split("\n");
                    let partial = [];
                    for(const level of allText) {
                        partial.push(level.split(","));
                    }

                    partial = partial.flat(1);

                    partial = partial.map(val => Number(val));

                    _this.data[index] = partial;

                    if(++filesLoaded === dataToLoad.length) {
                        console.log("files loaded");

                        _this.totalParticles = partial.length;

                        let positionData = [];
                        let colorData = [];

                        for(let i = 0; i < partial.length; i ++) {
                            positionData.push(_this.data[0][i], _this.data[1][i], _this.data[2][i]);
                            colorData.push(_this.data[3][i], _this.data[4][i], _this.data[5][i]);
                        }

                        _this.positionData = webGL.createBuffer(positionData);
                        _this.colorData = webGL.createBuffer(colorData);

                        gl.depthFunc(gl.LESS);

                        _this.animate();
                    }
                }
            }

            txtFile.open("GET", `./${file}`, true);
            txtFile.send();

            return null;

        });

        this.indexes = webGL.createBuffer([0, 1, 2, 3]);

        this.gui = new dat.GUI();
        this.waterLevel = 0.6;
        this.waterColor = [255 * 0.1, 255 * 0.15, 255 * 0.15];
        this.dirtyLevel = 5;
        this.rippleHeight = 0.00;
        this.rippleFrequency = 2;
        this.rippleSpeed = 0.2;
        this.specularPower = 120;
        this.normalDeformation = 0.04;
        this.specularIntensity = 0.05;
        this.blurSteps = 0;
        this.threshold = 0;
        this.useLeveling = false;
        this.gui.add(this, "waterLevel", 0, 1, 0.01);
        this.gui.add(this, "dirtyLevel", 1, 15, 1);
        this.gui.addColor(this, 'waterColor');


        let blurFolder = this.gui.addFolder("Smoothing");
        blurFolder.add(this, "useLeveling");
        blurFolder.add(this, "blurSteps", 0, 100, 1);
        blurFolder.add(this, "threshold", 0, 1, 0.001);


        let animationFolder = this.gui.addFolder("Ripples");
        animationFolder.add(this, "rippleHeight", 0, 0.2, 0.01);
        animationFolder.add(this, "rippleFrequency", 0, 2, 0.1);
        animationFolder.add(this, "rippleSpeed", 0, 2, 0.01);
        animationFolder.add(this, "normalDeformation", 0, 1, 0.01);
        animationFolder.add(this, "specularPower", 0, 200, 1);
        animationFolder.add(this, "specularIntensity", 0, 1, 0.01);
    }

    animate = () => {

        requestAnimationFrame(this.animate);

        this.camera.updateCamera(this.FOV, this.canvas.width / this.canvas.height, this.cameraDistance, this.invertCamera);

        console.log(this.camera.cameraTransformMatrix);
        console.log(this.camera.perspectiveMatrix);


        //Calculate the noise for the current frame
        if(!this.noiseReady || this.rippleSpeed > 0) {
            this.noiseReady = true;
            this.currentFrame += 0.02;

            //Render the height map in the noise texture
            gl.bindFramebuffer(gl.FRAMEBUFFER, this.noiseFB);
            gl.viewport(0, 0, this.noiseTextureSize, this.noiseTextureSize);
            gl.useProgram(this.noiseProgram);
            webGL.bindAttribBuffer(this.noiseProgram.indexes, this.indexes, 1);
            gl.uniform1f(this.noiseProgram.currentFrame, this.currentFrame);
            gl.uniform1f(this.noiseProgram.rippleHeight, this.rippleHeight);
            gl.uniform1f(this.noiseProgram.rippleFrequency, this.rippleFrequency);
            gl.uniform1f(this.noiseProgram.rippleSpeed, this.rippleSpeed);
            gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
            gl.disableVertexAttribArray(this.noiseProgram.indexes);
        }



        //Render the position of the particles
        gl.enable(gl.DEPTH_TEST);

        gl.bindFramebuffer(gl.FRAMEBUFFER, this.heightFB);
        gl.viewport(0, 0, this.canvas.height, this.canvas.height);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
        gl.useProgram(this.heightProgram);
        webGL.bindAttribBuffer(this.heightProgram.position, this.positionData, 3);
        gl.uniformMatrix4fv(this.heightProgram.cameraMatrix, false, this.camera.cameraTransformMatrix);
        gl.uniformMatrix4fv(this.heightProgram.perspectiveMatrix, false, this.camera.perspectiveMatrix);
        gl.drawArrays(gl.POINTS, 0, this.totalParticles);
        gl.disableVertexAttribArray(this.heightProgram.position);


        //Render the particles and its color
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.albedoFB);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        gl.useProgram(this.renderParticlesProgram);
        webGL.bindAttribBuffer(this.renderParticlesProgram.position, this.positionData, 3);
        webGL.bindAttribBuffer(this.renderParticlesProgram.color, this.colorData, 3);
        gl.uniformMatrix4fv(this.renderParticlesProgram.cameraMatrix, false, this.camera.cameraTransformMatrix);
        gl.uniformMatrix4fv(this.renderParticlesProgram.perspectiveMatrix, false, this.camera.perspectiveMatrix);
        gl.uniform1f(this.renderParticlesProgram.currentFrame, this.currentFrame);
        gl.uniform1f(this.renderParticlesProgram.sign, 1);
        gl.uniform1f(this.renderParticlesProgram.offset, 0);
        gl.uniform1i(this.renderParticlesProgram.levelHeight, 0);

        gl.drawArrays(gl.POINTS, 0, this.totalParticles);

        gl.clearColor(1, 1, 1, 1);

        //Render the reflection particles
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.reflectionFB);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        gl.uniformMatrix4fv(this.renderParticlesProgram.cameraMatrix, false, this.camera.cameraTransformMatrix);
        gl.uniformMatrix4fv(this.renderParticlesProgram.perspectiveMatrix, false, this.camera.perspectiveMatrix);
        gl.uniform1f(this.renderParticlesProgram.sign, -1);
        gl.uniform1f(this.renderParticlesProgram.offset, -2);
        gl.uniform1i(this.renderParticlesProgram.levelHeight, 0);
        gl.drawArrays(gl.POINTS, 0, this.totalParticles);


        //Render the water level (save fresnel, normal and height)
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.fresnelFB);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);


        gl.useProgram(this.renderParticlesProgram);
        gl.uniformMatrix4fv(this.renderParticlesProgram.cameraMatrix, false, this.camera.cameraTransformMatrix);
        gl.uniformMatrix4fv(this.renderParticlesProgram.perspectiveMatrix, false, this.camera.perspectiveMatrix);
        gl.uniform1f(this.renderParticlesProgram.currentFrame, this.currentFrame);
        gl.uniform1f(this.renderParticlesProgram.sign, 1);
        gl.uniform1f(this.renderParticlesProgram.offset, 0);
        gl.uniform1i(this.renderParticlesProgram.levelHeight, 0);
        gl.drawArrays(gl.POINTS, 0, this.totalParticles);


        //Render the particles using a level height changed by steps for water smoothing
        if(this.useLeveling) {
            gl.clear(gl.DEPTH_BUFFER_BIT);
            gl.colorMask(false, false, false, false);
            gl.uniform1i(this.renderParticlesProgram.levelHeight, 1);
            gl.drawArrays(gl.POINTS, 0, this.totalParticles);
            gl.colorMask(true, true, true, true);
        }

        gl.disableVertexAttribArray(this.renderParticlesProgram.position);
        gl.disableVertexAttribArray(this.renderParticlesProgram.color);

        //Render the plane on top of the particles in the framebuffer
        gl.useProgram(this.waterProgram);
        webGL.bindAttribBuffer(this.waterProgram.vertexPosition, this.vertices, 2);
        gl.uniformMatrix4fv(this.waterProgram.cameraMatrix, false, this.camera.cameraTransformMatrix);
        gl.uniformMatrix4fv(this.waterProgram.perspectiveMatrix, false, this.camera.perspectiveMatrix);
        webGL.bindTexture(this.waterProgram.choppy, this.noiseTexture, 0);
        gl.uniform1f(this.waterProgram.waterLevel, this.waterLevel);
        gl.uniform3fv(this.waterProgram.cameraPosition, [0, 0, 5]);
        gl.uniform2fv(this.waterProgram.planeSize, [this.planeWidth, this.planeHeight]);
        gl.drawArrays(gl.TRIANGLES, 0, this.amountOfTriangles);
        gl.disableVertexAttribArray(this.waterProgram.vertexPosition);


        gl.disable(gl.DEPTH_TEST);


        gl.viewport(0, 0, this.canvas.height / this.blurDivider, this.canvas.height / this.blurDivider);


        //Calculate the water mask used for the blur (smoothing)
        gl.useProgram(this.subtractProgram);
        webGL.bindAttribBuffer(this.subtractProgram.indexes, this.indexes, 1);
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.blur1FB);
        webGL.bindTexture(this.subtractProgram.texture1, this.albedoTexture, 0);
        webGL.bindTexture(this.subtractProgram.texture2, this.fresnelTexture, 1);
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
        gl.disableVertexAttribArray(this.subtractProgram.indexes);


        //Blur smoothing in one axis X
        gl.useProgram(this.blurProgram);
        webGL.bindAttribBuffer(this.blurProgram.indexes, this.indexes, 1);
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.blur2FB);
        webGL.bindTexture(this.blurProgram.texture, this.blur1Texture, 0);
        gl.uniform1i(this.blurProgram.steps, this.blurSteps);
        gl.uniform2fv(this.blurProgram.axis, [1 / this.size, 0]);
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);


        //Blur smoothing in the other axis Y
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.blur1FB);
        webGL.bindTexture(this.blurProgram.texture, this.blur2Texture, 0);
        gl.uniform1i(this.blurProgram.steps, this.blurSteps);
        gl.uniform2fv(this.blurProgram.axis, [0, 1 / this.size]);
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

        gl.disableVertexAttribArray(this.blurProgram.indexes);


        gl.viewport(0, 0, this.canvas.height, this.canvas.height);


        //Make the composition
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.clearColor(0, 0, 0, 0);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
        gl.useProgram(this.compoProgram);
        webGL.bindAttribBuffer(this.compoProgram.indexes, this.indexes, 1);
        webGL.bindTexture(this.compoProgram.albedo, this.albedoTexture, 0);
        webGL.bindTexture(this.compoProgram.fresnel, this.fresnelTexture, 1);
        webGL.bindTexture(this.compoProgram.reflection, this.reflectionTexture, 2);
        webGL.bindTexture(this.compoProgram.height, this.heightTexture, 3);
        webGL.bindTexture(this.compoProgram.thresholdTexture, this.blur1Texture, 4);
        gl.uniform1f(this.compoProgram.waterLevel, this.waterLevel);
        gl.uniform1f(this.compoProgram.dirtyLevel, this.dirtyLevel);
        gl.uniform3fv(this.compoProgram.waterColor, this.waterColor);
        gl.uniform1f(this.compoProgram.currentFrame, this.currentFrame);
        gl.uniform1f(this.compoProgram.specularPower, this.specularPower);
        gl.uniform1f(this.compoProgram.specularIntensity, this.specularIntensity);
        gl.uniform1f(this.compoProgram.normalDeformation, this.normalDeformation);
        gl.uniform1f(this.compoProgram.threshold, this.threshold);
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

        gl.disableVertexAttribArray(this.compoProgram.indexes);


    }

    render() {
        return (
            <div className="App">
                <canvas className = "canvas3D" />
            </div>
            );
    }
}

