
/*
 Gaussian blur used to smooth the water mask, the only difference is that
 if the fragment from the original mask don't have information the blur
 will force the result to be zero.
 */


const blurShader = `
    precision highp float;
    precision highp sampler2D;
    uniform sampler2D uDT;
    uniform vec2 uAxis;
    uniform int uSteps;

    varying vec2 uv;

    void main(void) {

        vec4 blend = vec4(0.);
        float sum = 1.;
        float m = 1.;
        float n = float(uSteps);
        float pass = 1.;

        for (int i = 0; i <= 100; i += 1) {

            float k = float(i);
            float j = float(i) - 0.5 * float(uSteps);
            blend += m * texture2D(uDT, uv + j * uAxis.xy);

            //If the original fragment don't have information then the result should be zero.
            if(abs(j) < 0.0001 && blend.a < 0.001) pass = 0.;

            m *= (n - k) / (k + 1.);
            sum += m;

            if(i > 2 * uSteps) break;
        }

        blend /= sum;

        gl_FragColor = blend * pass;
    }
`;

export {blurShader}