

/*
Shader used to render a 2D quad on the screen, usually used to apply
Fragment shader operations on compositions
 */

const quadShader = `
    attribute float aIndexes;
    varying vec2 uv;

    void main() {
        vec2 position = 2. * vec2(mod(aIndexes, 2.), floor(aIndexes / 2.)) - vec2(1.);
        uv = 0.5 * position + vec2(0.5);
        gl_Position = vec4(position, 0., 1.);
    }
`;

export {quadShader}