
/*
Simple color shader used to render the color defined for the
fragment in the vertex shader.
 */

const colorShader = `
    precision highp float;

    varying vec4 colorData;

    void main() {
        gl_FragColor = colorData;
    }
`;

export {colorShader}