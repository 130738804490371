


/*
Shader used to make the final composition of the water with the ripples,
the smoothing and the reflection / refraction effect
 */

const compoShader = `

precision highp float;

varying vec2 uv;

uniform sampler2D albedo;
uniform sampler2D fresnel;
uniform sampler2D reflection;
uniform sampler2D height;
uniform sampler2D thresholdTexture;
uniform float waterLevel;
uniform vec3 waterColor;
uniform float dirtyLevel;
uniform float currentFrame;
uniform float specularPower;
uniform float specularIntensity;
uniform float normalDeformation;
uniform float threshold;

void main() {

    vec4 albedoData = texture2D(albedo, uv);
    vec4 fresnelData = texture2D(fresnel, uv);
    vec4 heightData = texture2D(height, uv);
    vec4 thresholdData = texture2D(thresholdTexture, uv);


    gl_FragColor = mix(albedoData, fresnelData, vec4(albedoData.a));


    if(gl_FragColor != albedoData) {

        //Fresnel data: rg = normal.xy, b = height, a = fresnel
        float fresnel = fresnelData.a;

        //Water color is defined by the user
        vec3 ww = waterColor / 255.;
        vec4 wColor = vec4(ww, 1.);


        //The normal is always normalized, so I only need to encode two values in the fragment from the texture.
        vec3 normal;
        normal.xy = fresnelData.xy;
        normal.z = pow(1. - normal.x * normal.x - normal.y * normal.y, 0.5);


        //the refraction and reflection work by modifying the UV using the normals, the idea is that if the water is
        //flat the normal will be [0, 1, 0], but if the normal is perturbed the XZ components will show the strength of
        //the perturbation.
        vec4 reflectionData = texture2D(reflection, uv - normalDeformation * normal.xz);
        vec4 refractionData = texture2D(albedo, uv + normalDeformation * normal.xz);


        //This is an artistic effect, can be modified depending on how the client wants the reflection intensity.
        reflectionData.rgb *= 0.5;


        //Change water color based on diffuse
        vec3 light = vec3(0., 1., 0.);
        vec3 eye = normalize(vec3(0., 4., 1.));
        wColor.rgb *= dot(light, normal);


        //For the specular highlights
        vec3 waterPos = vec3(heightData.x, fresnelData.z, heightData.z);
        wColor.rgb += specularIntensity * vec3(pow(max(dot(normalize(reflect(light, normal)), -eye), 0.), specularPower));


        //For refraction
        wColor = mix(refractionData, wColor, 1. - vec4(fresnel));


        //Darken refraction based on the height
        float waterHeight = clamp(dirtyLevel * abs((- 1.45 + 0.5 * waterLevel + fresnelData.z) - heightData.y), 0., 1.);
        wColor = mix(wColor, vec4(ww, 1.), vec4(waterHeight));


        //For reflection
        wColor = mix(wColor, reflectionData, vec4(fresnel));


        //Notice how the color is updated on different parts, this allows to remove any step non needed in the composition.
        gl_FragColor = wColor;

    }


    //Smooth the water, this uses the blur and the zLevel to smooth the borders of the water.
    if(thresholdData.r < threshold) gl_FragColor = albedoData;


    //This frames the composition showing a rectangular result. It's based on the fact that the canvas is 1800 x 1800
    //values should be changed if the canvas size is modified
    if(gl_FragCoord.y < 630. || gl_FragCoord.y > 1070. || gl_FragCoord.x < 90. || gl_FragCoord.x > 1700.) gl_FragColor = vec4(0.);

}

`;

export {compoShader}