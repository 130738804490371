

/*
Shader used to render the 3D position of the data set,
this is used to calculate the height difference between the water
level and the scene. The difference modifies the absorption level
of the water.
 */


const heightShader = `

    attribute vec3 position;

    uniform mat4 uCameraMatrix;
    uniform mat4 uPMatrix;

    varying vec4 colorData;

    void main() {

        vec4 pp = vec4(-position.x, position.z, position.y , 1.);
        colorData = pp;

        gl_Position = uPMatrix * uCameraMatrix * pp;

        gl_PointSize = 1.7;

    }
`;

export {heightShader}