

/*
Shader used to render a plane that is deformed with a previous noise
calculation, the Y value is updated by the user to reflect the height
of the water in the scene
 */

const planeShader = `

attribute vec2 vertexPosition;

uniform mat4 uCameraMatrix;
uniform mat4 uPMatrix;
uniform float waterLevel;
uniform sampler2D choppy;
uniform vec2 planeSize;

varying vec3 normal;
varying vec3 position3D;

void main() {

    position3D = vec3(vertexPosition.x, 0., vertexPosition.y);

    vec2 uv = (vertexPosition + 0.5 * planeSize) / planeSize;


    //This is a noise calculated from the noise shader.
    vec4 choppyData = texture2D(choppy, uv);


    //The height depends on the noise and the water level defined
    //by the user, notice that the "-1.45" is an arbitrary lowest level
    //it should be provided by the data from the scene.
    position3D.y += - 1.45 + 0.5 * waterLevel + choppyData.r;


    //This is to center the plane on the particle data set
    position3D.z += 40.;
    position3D.x += 10.;


    normal = choppyData.gba;


    gl_Position = uPMatrix * uCameraMatrix * vec4(position3D, 1.);
}

`;

export {planeShader}