

/*
Shader used to evaluate where the water is allocated in the composition.
The water is defined by a perturbed plane that must be rendered with the
particles, this way the water takes advantage of the Z buffer test to render
it properly in the composition.

The subtract shader generates a mask in a framebuffer that is way smaller than
the canvas size, this allows to make a fast 2D blur to smooth the water.
 */


const subtractShader = `
    precision highp float;

    varying vec2 uv;

    uniform sampler2D texture1;
    uniform sampler2D texture2;

    void main() {

        vec4 color1 = texture2D(texture1, uv);
        vec4 color2 = texture2D(texture2, uv);

        //The fragment will be white where there's water in the composition
        gl_FragColor = all(equal(color1, color2)) ? vec4(0.) : vec4(1.);

    }
`;

export {subtractShader}