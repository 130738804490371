

/*
Shader used to save the information for the water in the composition,
it calculates the fresnel value used for the reflection and refraction,
also saves the XY components of the normal from the perturbed mesh plane
so it can perturb the reflection and refraction too.

Finally it saves the height so the water difference can be calculated in the
composition shader, that allows to modify the absorption of the water.
 */


const waterShader = `
    precision highp float;

    uniform vec3 cameraPosition;

    varying vec3 position3D;
    varying vec3 normal;

    void main() {

        float n1 = 1.4;
        float n2 = 1.;

        float R0 = (n1 - n2) / (n1 + n2);
        R0 *= R0;

        vec3 N = vec3(0., 1., 0.);
        vec3 V = normalize(cameraPosition - position3D);

        float R = R0 + (1. - R0) * pow(1. - dot(N, V), 8.);

        gl_FragColor = vec4(normal.xy, position3D.y, R);

    }
`;

export {waterShader}